import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typed from 'typed.js';
import Header from '../components/Header';
import Page from '../components/Page';
import Content from '../components/styles/Content';
import Center from '../components/styles/Center';

function IndexPage() {
  const TypedStyle = styled.div`
    > span {
      font-size: 3rem;
      font-family: ${props => props.theme.fontMono};
    }
    @media (max-width: ${props => props.theme.smallWidth}) {
      > span {
        font-size: 2rem;
      }
    }
  `;

  const typedOptions = {
    stringsElement: '#typed-strings',
    typeSpeed: 25,
    backSpeed: 50,
    backDelay: 1000,
    startDelay: 1000
  };
  const spanRef = useRef(null);
  useEffect(() => {
    const typed = new Typed(spanRef.current, typedOptions);
    return () => typed.destroy();
  }, [typedOptions]);
  return (
    <Page>
      <>
        <Header />
        <Content>
          <Center
            style={{
              width: '90%'
            }}
          >
            <div>
              <TypedStyle style={{ display: 'none' }} id="typed-strings">
                <p>
                  Hello{' '}
                  <span role="img" aria-label="hand wave emoji">
                    👋
                  </span>
                  {', '}
                  {Array(Math.floor(500 / typedOptions.typeSpeed)).join(' ')}
                  I'm Erdem, software engineer living in Stockholm, Sweden.
                  <br />
                  {Array(Math.floor(500 / typedOptions.typeSpeed)).join(' ')}
                  I'm building things which help humanity thrive.
                </p>
              </TypedStyle>
              <TypedStyle>
                <span ref={spanRef} />
              </TypedStyle>
            </div>
          </Center>
        </Content>
      </>
    </Page>
  );
}

export default IndexPage;
